body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

p,
li {
    font-size: 1.1em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

[id] {
    scroll-margin-top: 100px;
}

/** Custom Code here **/

// Main Color
$dark: #032116;
$soft-dark: #293039;
$warning: #ff6a03;
$success: #90c53f;
$light: #f2f7ff;

.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    color: $warning;
}

.title {
    font-family: 'Bebas Neue', sans-serif;
}

.bg-dark {
    background-color: $dark;
    color: #FFF
}

.bg-soft-dark {
    background-color: $soft-dark !important;
}

.text-warning {
    color: $warning
}

/** Custom Code here **/

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";